import { api } from "./api";

const URLS = {
    tags: "tags",
    solutions: "sme/solutions",
    mySolutions: "sme/solutions/current-user",
    specificSolution: "sme/solution"
};

// All Solutions --- NOT TO BE USED

// export const getAllSolutions = () => {
//     return api.get(`${URLS.solutions}`);
// };

export const getUserSolutions = () => {
    return api.get(`${URLS.mySolutions}`);
};

export const showSolution = (payload) => {
    return api.get(`${URLS.specificSolution}/${payload.id}`);
};

// Solutions With Filtering --- NOT TO BE USED

// export const getParams = (payload) => {
//     return api.get(`${URLS.opportunity}/params`);
// };

export const createSolution = (payload) => {
    return api.post(`${URLS.specificSolution}`, payload);
};

export const updateSolution = (payload) => {
    return api.post(`${URLS.specificSolution}/${payload.id}`, payload.data);
};

export const deleteSolution = (payload) => {
    return api.delete(`${URLS.specificSolution}/${payload.id}`);
};

export const getSolutionTags = () => {
    return  api.get(`${URLS.tags}`);
}