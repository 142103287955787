<template>
    <v-container class="my-10">
        <div :class="`${id ? 'background-container-edit': 'background-container-create'}`" />
        <div
            fluid
            class="d-flex align-center justify-space-between mb-10"
        >
            <div class="d-flex">
                <v-btn
                    icon
                    color="accent darken-3"
                    class="align-self-center background"
                    link
                    :to="backToPrevRoute"
                >
                    <v-icon left> fa-solid fa-chevron-left </v-icon>
                </v-btn>
                <h2 class="primary--text main-heading">
                    {{ formCreateEditComputed.pageTitle }}
                </h2>
            </div>
            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-spacer></v-spacer>
        </div>
        <base-card class="container mb-15">
            <template #content>
                <v-container>
                    <div class="d-flex align-center">
                        <v-icon left color="primary">
                            {{ formCreateEditComputed.icon }}
                        </v-icon>
                        <h1 class="main-heading small-text">
                            {{ formCreateEditComputed.formTitle }}
                        </h1>
                    </div>
                    <text-input
                        :input-value.sync="formValues.title"
                        :current-value="formValues.title"
                        :in-valid.sync="isTitleInvalid"
                        :validation.sync="validationErrors"
                        :label="$t(`SME.CreateEditSolutionForm.solutionTitle`)"
                    >
                    </text-input>

                    <text-area
                        :input-value.sync="formValues.description"
                        :current-value="formValues.description"
                        :in-valid.sync="isDescriptionInvalid"
                        :label="
                            $t(`SME.CreateEditSolutionForm.solutionDescription`)
                        "
                    >
                    </text-area>

                    <upload-input
                        :image-url.sync="formValues.image_url"
                        :current-image="formValues.image_url"
                        :validation.sync="validationErrors"
                        :in-valid.sync="isImageUrlInalid"
                        @delete-image="deleteImage()"
                    >
                    </upload-input>

                    <social-media-input
                        :input-value.sync="formValues.website_url"
                        :current-value="formValues.website_url"
                        :in-valid.sync="isWebsiteLinkInvalid"
                        icons="fa-solid fa-link"
                        :label="
                            $t(`SME.CreateEditSolutionForm.solutionWebsiteLink`)
                        "
                    >
                    </social-media-input>

                    <select-input
                        :input-value.sync="formValues.tags"
                        :current-value="formValues.tags"
                        :in-valid.sync="isTagsInvalid"
                        key-value="name"
                        :items="tags"
                        icons="fa-solid fa-tags"
                        :label="$t(`SME.CreateEditSolutionForm.solutionTags`)"
                    >
                    </select-input>

                    <h1 class="social-media-title small-text">
                        {{ formCreateEditComputed.formSocialMediaTitle }}
                    </h1>
                    <div
                        :class="`d-md-flex flex-wrap justify-center ${
                            id ? 'mt-10' : 'mt-0'
                        }`"
                        >
                        <div class="d-flex flex-wrap justify-center gap">
                            <social-media-input
                                :input-value.sync="formValues.links[0].link"
                                :current-value="formValues.links[0].link"
                                :in-valid.sync="isSocialMediaLinksInvalid"
                                icons="fab fa-facebook-f"
                                :label="
                                    $t(`SME.CreateEditSolutionForm.facebook`)
                                "
                            >
                            </social-media-input>

                            <social-media-input
                                :input-value.sync="formValues.links[1].link"
                                :current-value="formValues.links[1].link"
                                :in-valid.sync="isSocialMediaLinksInvalid"
                                icons="fab fa-twitter"
                                :label="
                                    $t(`SME.CreateEditSolutionForm.twitter`)
                                "
                            >
                            </social-media-input>

                            <social-media-input
                                :input-value.sync="formValues.links[2].link"
                                :current-value="formValues.links[2].link"
                                :in-valid.sync="isSocialMediaLinksInvalid"
                                icons="fab fa-linkedin-in"
                                :label="
                                    $t(`SME.CreateEditSolutionForm.linkedin`)
                                "
                            >
                            </social-media-input>

                            <social-media-input
                                :input-value.sync="formValues.links[3].link"
                                :current-value="formValues.links[3].link"
                                :in-valid.sync="isSocialMediaLinksInvalid"
                                icons="fab fa-youtube"
                                :label="
                                    $t(`SME.CreateEditSolutionForm.youtube`)
                                "
                            >
                            </social-media-input>
                        </div>
                    </div>
                    <v-divider class="my-5"></v-divider>
                    <v-row class="align-center mr-4">
                        <v-col
                            cols="12"
                            md="6"
                            sm="12"
                            class="d-flex justify-center justify-md-start mt-5"
                        >
                            <ul v-if="validationErrors.length != 0">
                                <div class="d-flex">
                                    <v-icon small left color="warning">
                                        fa fa-warning
                                    </v-icon>
                                    <h3 class="main-heading">
                                        Form failed to Submit!
                                    </h3>
                                </div>
                                <li
                                    v-for="(item, index) in validationErrors"
                                    :key="index"
                                    style="list-style: none"
                                    class="error--text text-sm-center"
                                >
                                    {{ item.toString() }}
                                </li>
                            </ul>
                            <p v-if="formValues.image_url == '' && validationErrors.length == 0"
                             class="d-block error--text text-sm-center"
                            >
                             Image is required
                            </p>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            sm="12"
                            class="d-flex justify-center justify-md-end mt-5"
                        >
                            <v-btn
                                depressed
                                color="primary"
                                :loading="
                                    updateUserSolutionStatus_Pending ||
                                    createUserSolutionStatus_Pending
                                "
                                :disabled="toggleSubmitButton"
                                @click="selectAction()"
                            >
                                <v-icon left small> fa-solid fa-save </v-icon>
                                {{ formCreateEditComputed.formButton }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import {
    getSolutionTags,
    showSolution,
    createSolution,
    updateSolution,
} from "@/api/smeSolutionsApi.js";
import BaseCard from "../../components/base/BaseCard.vue";
import TextInput from "../../components/Inputs/TextInput.vue";
import TextArea from "../../components/Inputs/TextArea.vue";
import UploadInput from "../../components/Inputs/UploadInput.vue";
import SelectInput from "../../components/Inputs/SelectInput.vue";
import SocialMediaInput from "../../components/Inputs/SocialMediaInput.vue";
import SolutionImage from "./components/SolutionImage.vue";

export default {
    name: "CreateSolutionPage",
    props: {
        id: {
            required: false,
            type: [Number, String],
            default: null,
        },
    },
    components: {
        BaseCard,
        UploadInput,
        SelectInput,
        TextInput,
        TextArea,
        SocialMediaInput,
        SolutionImage,
    },
    data() {
        return {
            getSolutionTagsStatus: apiStatus.Idle,
            createUserSolutionStatus: apiStatus.Idle,
            updateUserSolutionStatus: apiStatus.Idle,
            getUserSolutionByIdStatus: apiStatus.Idle,
            imageChanged: false,
            tagsChanged: false,
            formChanged: false,
            tags: [],
            formValues: {
                links: [
                    {
                        id: 1,
                        link: "",
                        name: "Facebook",
                    },
                    {
                        id: 2,
                        link: "",
                        name: "Twitter",
                    },
                    {
                        id: 3,
                        link: "",
                        name: "LinkedIn",
                    },
                    {
                        id: 4,
                        link: "",
                        name: "Youtube",
                    },
                ],
                tags: [],
                website_url: "https://",
                image_url: ''
            },
            validationErrors: [],
            isTitleInvalid: this.id ? false : true,
            isDescriptionInvalid: this.id ? false : true,
            isImageUrlInalid: this.id ? false : true,
            isWebsiteLinkInvalid: this.id ? false : true,
            isTagsInvalid: this.id ? false : true,
            isSocialMediaLinksInvalid: this.id ? false : true,
        };
    },
    computed: {
        ...apiStatusComputed([
            "getSolutionTagsStatus",
            "createUserSolutionStatus",
            "updateUserSolutionStatus",
            "getUserSolutionByIdStatus",
        ]),
        backToPrevRoute() {
            return { name: "My Solutions" };
            //  return this.$router.go(-1);
        },
        toggleSubmitButton() {
            if (this.id) {
                for (let key in this.formValues) {
                    if (this.formValues.hasOwnProperty(key)) {
                        return this.isValidated();
                    }
                }
                return true;
            }
            return this.isValidated();
        },
        formCreateEditComputed() {
            return {
                pageTitle: this.id
                    ? this.$t(
                          `SME.CreateEditSolutionForm.pageTitleBannerEdit`
                      ) +
                      " - " +
                      `${this.id}`
                    : this.$t(
                          `SME.CreateEditSolutionForm.pageTitleBannerCreate`
                      ),
                icon: this.id ? "fa-solid fa-edit" : "fa-solid fa-pen",
                formTitle: this.id
                    ? this.$t(`SME.CreateEditSolutionForm.formTitleEdit`)
                    : this.$t(`SME.CreateEditSolutionForm.formTitleCreate`),
                formButton: this.id
                    ? this.$t(`SME.CreateEditSolutionForm.formEditButton`)
                    : this.$t(`SME.CreateEditSolutionForm.formSubmitButton`),
                formSocialMediaTitle: this.id
                    ? ""
                    : this.$t(
                          "SME.CreateEditSolutionForm.formSocialMediaTitle"
                      ),
            };
        },
    },
    methods: {
        deleteImage() {
            this.formValues.image_url = '';
        },
        async getSolutionTags() {
            this.getSolutionTagsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getSolutionTags);

            if (error) {
                this.getSolutionTagsStatus = apiStatus.Error;
                return;
            }

            this.tags = response.data.data;
            this.getSolutionTagsStatus = apiStatus.Success;
            this.tags = this.tags.map(tag => {
                return {
                    id: tag.id,
                    name: tag.name.charAt(0).toUpperCase() + tag.name.slice(1)
                }
            })
        },
        async createSolution() {
            this.createUserSolutionStatus = apiStatus.Pending;

            let formatedData = new FormData();

            let links = [];
            this.formValues.links.filter((val) => {
                if (val.link != "") {
                    links.push(val);
                }
            });

            formatedData.append("name", this.formValues.title);
            formatedData.append("description", this.formValues.description);
            formatedData.append("image_file", this.formValues.image_url);
            formatedData.append("website_url", this.formValues.website_url);
            formatedData.append("tags", this.formValues.tags);
            if (links.length > 0) {
                formatedData.append("link_number", links.length);
            }

            let idx = 0;
            this.formValues.links.forEach((link, index) => {
                if (link.link != "") {
                    idx++;
                    formatedData.append(`link_${idx}`, link.link);
                    formatedData.append(`type_${idx}`, link.id);
                }
            });

            const { response, error } = await withAsync(
                createSolution,
                formatedData
            );

            if (error) {
                this.createUserSolutionStatus = apiStatus.Error;
                this.validationErrors =
                    error.response.data.metadata.response_message;
                return;
            }
            this.createUserSolutionStatus = apiStatus.Success;
            this.$router.push({ name: "My Solutions" });
        },
        async getSolutionById() {
            this.getUserSolutionByIdStatus = apiStatus.Pending;

            let payload = {
                id: this.id,
            };

            const { response, error } = await withAsync(showSolution, payload);

            if (error) {
                this.getUserSolutionByIdStatus = apiStatus.Error;
                return;
            }
            this.getUserSolutionByIdStatus = apiStatus.Success;

            let res = response.data.data.links;

            res.forEach((link) => {
                if (link.name == "Facebook") {
                    return (this.formValues.links[0] = link);
                } else if (link.name == "Twitter") {
                    return (this.formValues.links[1] = link);
                } else if (link.name == "LinkedIn") {
                    return (this.formValues.links[2] = link);
                } else if (link.name == "Youtube") {
                    return (this.formValues.links[3] = link);
                }
            });

            let formatedData = this.formValues.links;
            this.formValues = response.data.data;
            this.formValues.links = formatedData;
        },
        async updateSolution() {
            this.updateUserSolutionStatus = apiStatus.Pending;

            let formatedData = new FormData();
            let links = [];
            this.formValues.links.filter((val) => {
                if (val.link != "") {
                    links.push(val);
                }
            });
            if (this.tagsChanged) {
                formatedData.append("tags", this.formValues.tags);
            }
            formatedData.append("name", this.formValues.title);
            formatedData.append("description", this.formValues.description);
            if (this.imageChanged) {
                formatedData.append("image_file", this.formValues.image_url);
            }
            formatedData.append("website_url", this.formValues.website_url);
            formatedData.append("link_number", links.length);

            let idx = 0;
            this.formValues.links.forEach((link, index) => {
                if (link.link != "") {
                    idx++;
                    formatedData.append(`link_${idx}`, link.link);
                    formatedData.append(`type_${idx}`, link.id);
                }
            });

            let payload = {
                id: this.id,
                data: formatedData,
            };

            const { response, error } = await withAsync(
                updateSolution,
                payload
            );

            if (error) {
                this.updateUserSolutionStatus = apiStatus.Error;
                this.validationErrors =
                    error.response.data.metadata.response_message;
                return;
            }
            this.formChanged = false;
            this.updateUserSolutionStatus = apiStatus.Success;
            this.$router.push({ name: "My Solutions" });
        },
        selectAction() {
            this.id ? this.updateSolution() : this.createSolution();
        },
        isValidated() {
            if (this.isTitleInvalid) return true;
            if (this.isDescriptionInvalid) return true;
            if (this.isImageUrlInalid) return true;
            if (this.isTagsInvalid) return true;
            if (this.isWebsiteLinkInvalid) return true;
            // if(this.isSocialMediaLinksInvalid) return true;
        },
    },
    watch: {
        "formValues.image_url"(newVal, oldVal) {
            if (oldVal != undefined) {
                this.imageChanged = !this.imageChanged;
            }
        },
        "formValues.tags"(newVal, oldVal) {
            if (oldVal.length != 0) {
                this.tagsChanged = true;
            }
        },
        formValues: {
            handler(newVal, oldVal) {
                if (oldVal == newVal) {
                    this.formChanged = !this.formChanged;
                }
            },
            deep: true,
        },
    },
    created() {
        this.getSolutionTags();
        if (this.id) {
            this.getSolutionById();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.id && this.formChanged) {
            let confirm = window.confirm(
                "You have unsaved changes, Are you sure you want to leave?"
            );
            if (confirm) {
                next();
            }
        } else {
            next();
        }
    },
};
</script>

<style scoped>
.gap {
    gap: 1rem !important;
}
.social-media-title {
    font-family: "Yanone Kaffeesatz", sans-serif !important;
    font-size: 1.5rem;
}
.container {
    background: rgba(255, 255, 255, 0.9);
    /* position: relative; */
    z-index: 2;
}
.background-container-create, .background-container-edit {
    position: relative;
}
.background-container-create::before {
    content: "";
    width: 300px;
    height: 950px;
    position: absolute;
    z-index: 1;
    top: 85px;
    left: -20px;
    filter: blur(1px);
    border-radius: 10px;
    background-image: linear-gradient(50deg, #eee 0%, #fff 100%);
}
.background-container-edit::before {
    content: "";
    width: 300px;
    height: 1100px;
    position: absolute;
    z-index: 1;
    top: 85px;
    left: -20px;
    filter: blur(1px);
    border-radius: 10px;
    background-image: linear-gradient(50deg, #eee 0%, #fff 100%);
}

@media screen and (max-width: 860px) {
    .small-text {
        font-size: 1rem;
    }
}
</style>
