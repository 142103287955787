<template>
  <v-col
   cols="12"
  >
    <v-img
      class="hidden-md-and-down"
      max-height="850"
      src="../../../assets/solutionImage.svg"
    >
    </v-img>
  </v-col>
</template>

<script>
export default {
  name: 'SolutionImage',
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>